<!--
	@name: designForm
	@description：designForm
	@author: ZengWei
	@date: 2021-09-09 15:45
-->
<template>
  <div style="height: 100%; position: relative; background: #fff" v-loading="loading">
    <iframe
      ref="designForm"
      v-if="iframeSrc"
      :src="iframeSrc"
      style="width: 100%; height: 100%"
      frameborder="0"
    ></iframe>
    <el-button type="warning" class="fixButton" @click="$router.back()">返回</el-button>

		<el-dialog
				title="表单设计预览"
				:visible.sync="dialogVisible"
				:fullscreen="true"
				custom-class="vertical-center"
		>
			<div>
				<parser
					v-if="previewData && dialogVisible"
					ref="formParser"
					:form-data="previewData"
					:hide-btn="false"
					@submit="handleSubmit"
				>
				</parser>
				<skeleton v-else></skeleton>
			</div>
			<!--<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="dialogVisible = false">确 定</el-button>
			</span>-->
		</el-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import parser from "@/components/formParser/parser_pc";
import { formSrc } from "@/libs/util";
import { formDesign } from "@/api/home";
import skeleton from "@/components/formParser/skeleton";

export default {
  name: "designForm",
  components: {parser,skeleton},
  props: {
    path: {
      type: String,
      default: formSrc() + "/#/design",
    },
  },
  data() {
    return {
	    designId: this.$route.query.designId,
      loading: true,
      iframeWindow: null,
      formDesignData: null,
	    dialogVisible:false,
			previewData: null,
			moduleName: '',
      timer: null,
      iframeSrc: null
    };
  },
  created() {
    window.saveFormDesign = this.saveFormDesign.bind(this);
    window.changeDisplay = this.changeDisplay.bind(this);
    window.previewPC = this.previewPC.bind(this);
  },
  mounted() {
    const obuuid = this.$route.query.obuuid
    if(obuuid){
      this.iframeSrc = formSrc() + "/#/design?obuuid=" + obuuid;
    } else {
      this.iframeSrc = formSrc() + "/#/design";
    }
    this.initIframeWindow();
  },
  methods: {
    initIframeWindow() {
      const _this = this;
      this.timer = setInterval(() => {
        const iframe = this.$refs.designForm?.contentWindow;
        if (typeof iframe.formDesignRecover === "function") {
          clearInterval(_this.timer);
	        formDesign.formShow(this.designId).then(res=>{
		        if (res.data.code == 200) {
			        _this.moduleName = res.data.data.module_name
			        _this.formDesignData = JSON.parse(res.data.data.json);
			        if(_this.formDesignData){
				        iframe.formDesignRecover(JSON.stringify(_this.formDesignData));
							}
		        }
		        _this.loading = false;
					})
        }
      }, 100);
    },
    saveFormDesign(formData) {
      let data = {
      	module_name:this.moduleName,
        json: JSON.stringify(formData),
      };
      this.$confirm("请确认相关配置配置完成, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
				formDesign.formUpdate(this.designId,data).then(res=>{
					if (res.data.code == 200) {
						this.$message({
							type: "success",
							message: res.data.msg,
						});
					}
				})
			}).catch(() => {});
    },
	  handleSubmit(data){
		  data.module_uuid = this.designId
		  /*formDesign.formDataStore(data).then(res=>{
			  if (res.data.code == 200) {
				  this.$message({
					  type: "success",
					  message: res.data.msg,
				  });
			  }else{
				  this.$message({
					  type: "error",
					  message: res.data.msg,
				  });
				}
			})*/
		},
    changeDisplay(mode) {
      if (mode === 1) {
        this.$router.replace({
          name: "objectDetail",
          query: this.$route.query,
        });
      }
    },
	  previewPC(formDesignData){
			this.previewData = formDesignData
		  this.dialogVisible = true
		},
  },
  destroyed() {
    if(this.timer){
      clearInterval(this.timer)
    }
  }
};
</script>

<style scoped>

.fixButton{
  position: fixed;
  right:20px;
  bottom: 20px;
}
</style>
